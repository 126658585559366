import type { FleamarketCategory, Maybe } from "__generated__/jampot/alpha-ca";
import { match, P } from "ts-pattern";

export type ArticleThumbnailParams = {
  thumbnail?: Maybe<string>;
  category?: Maybe<Pick<FleamarketCategory, "thumbnail">>;
};
export const getArticleThumbnail = (article: ArticleThumbnailParams) => {
  if (article.thumbnail || article.category) {
    return article.thumbnail ?? article.category?.thumbnail;
  }

  return null;
};

type ArticleCarouselItemParams = {
  images: string[];
  category?: Maybe<Pick<FleamarketCategory, "thumbnail">>;
};
export const getArticleCarouselSlides = ({
  images,
  category,
}: ArticleCarouselItemParams) => {
  return match({
    images,
    category,
  })
    .with({ images: P.when((i) => i.length > 0) }, (v) => {
      return v.images.map((image) => ({
        src: image,
      }));
    })
    .with(
      {
        images: P.when((i) => i.length === 0),
        category: P.not(P.nullish),
      },
      (v) => {
        return [
          {
            src: v.category.thumbnail,
          },
        ];
      },
    )
    .otherwise(() => []);
};
